<template>

            <form @submit.prevent="save">
                <div class="form-group">
                    <label for="name">Título del documento</label>
                    <input type="text" v-model="model.name" required id="name" class="form-control form-control-lg" placeholder="Escribe un título para el documento">
                </div>
                <div class="form-group">
                    <label>Categoría</label>
                    <select class="form-control" v-model="model.category" required="">
                        <option value="">Selecciona</option>
                        <option value="none">Sin categoría</option>
                        <option value="contracts">Contratos</option>
                        <option value="sales">Ventas</option>
                    </select>
                </div>


                <div class="form-group">
                    <label for="name">Contenido</label>

                    <vue-editor ref="editor" v-model="model.content" :editorOptions="editorOptions"></vue-editor>
                </div>


                <div class="form-group">
                    <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>
                </div>
            </form>

</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import api from '@/util/api.js';

import { VueEditor } from "vue2-editor";



export default {
    routeName:'documents',
    name: 'formDocument',
    components:{
        submitbutton,
        VueEditor
    },
    props:{

        id:{
            type:Number,
            required:false,
        },
        development_id:{
            default:''
        }

    },  
    data(){

        return {
            
            model:{
                id:'',
                name:'',
                category:'',
                content:'',
                development_id:''
            },
            
            pic:'',
            loading:false,

            editorOptions:{
            modules: {
               toolbar:{

                container:[
                        ['bold', 'italic', 'underline', 'strike'], // Estilos de texto
                        ['blockquote', 'code-block'], // Bloques de texto
                        ['table'], // Bloques de texto
                        [{ 'header': 1 }, { 'header': 2 }], // Encabezados
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }], // Listas ordenadas y no ordenadas
                        [{ 'script': 'sub'}, { 'script': 'super' }], // Subíndice y superíndice
                        [{ 'indent': '-1'}, { 'indent': '+1' }], // Sangría
                        [{ 'direction': 'rtl' }], // Dirección del texto
                        [{ 'size': ['small', false, 'large', 'huge'] }], // Tamaño de fuente
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Tamaño de encabezado
                        [{ 'color': [] }, { 'background': [] }], // Color de texto y fondo
                        [{ 'font': [] }], // Fuente
                        [{ 'align': [] }], // Alineación del texto
                        ['clean'], // Eliminar estilos
                        ['link', 'image', 'video'], // Insertar enlaces, imágenes y videos
                        ['customSelect']
                        ],

                handlers: {
                    'client_name': function() {
                            this.quill.insertText(this.quill.getSelection().index, '[client_name]', 'Nombre del cliente');
                            }
                        }
               }

                        

                }
            }


        }

    },

    methods:{


        selectFile(event) {
         this.model.icon = event.target.files[0];
        },

        save(){

            
            const data = this.createFormData(this.model);
            this.loading=true;

            api.request({
                url:this.me(this.isNewRecord?'/':this.model.id),
                data,
                headers:{'Content-type':'form/data'},
                method:this.isNewRecord?'POST':'PATCH',
                }).then(response=>{

                    this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});
                    this.$emit('afterSave',response);
                    
                    
                }).catch(error=>{
                    
                    this.$notify({message:"Ocurrió un error",type:"error"});
                    console.log(error);

                }).finally(()=>{

                        this.loading=false;
                });
            
        }




    },


    
    
    mounted(){


        this.model.development_id=this.development_id;
    
        if(!this.isNewRecord){
                api.get(this.me(this.$props.id))
                .then(response=>{
                    
                        const data=response.data;

                        for (const key in data) {
                            if (this.model.hasOwnProperty(key)) {
                                this.model[key] = data[key];
                            }
                        }


                        
                        this.pic=this.$apiUrl+'/../..'+data.icon.replace('.','-sm.');


                }).catch(error=>{
                    console.log(error);
                });

        }



const customSelect = this.$refs.editor.quill.getModule('toolbar').container.querySelector('.ql-customSelect');
    customSelect.innerHTML = `
      <select class="ql-clientSelect ql-picker" style="width: 150px">
        <option value="">Insertar valor del sistema</option>
        <optgroup label="Datos del cliente">
            <option value="client_name">Nombre del cliente</option>
            <option value="client_phone">Teléfono</option>
            <option value="client_address">Dirección</option>
            <option value="client_country">País</option>
            <option value="client_state">Estado</option>
            <option value="client_city">Ciudad</option>
            <option value="client_postal_code">Código postal</option>
            <option value="client_ine">INE</option>
            <option value="client_curp">CURP</option>
            <option value="client_email">Correo</option>
            
        </optgroup>
        <optgroup label="Datos de la unidad/lote">
            <option value="property_code">Número/clave de la unidad</option>
            <option value="property_price">Precio de catálogo de la unidad</option>
            <option value="property_model">Modelo de la unidad</option>
            <option value="property_m2_inside">M2 de construcción</option>
            <option value="property_m2_outside">M2 de terraza</option>
        </optgroup>

        <optgroup label="Pagos">
            <option value="property_sale_price">Precio final de venta</option>
            <option value="down_payment">Valor del enganche</option>
            <option value="deposit">Monto para apartado</option>
            <option value="no_payments">Total de mensualidades</option>
            <option value="payments">Calendario de pagos</option>

        </optgroup>

      </select>
    `;
    const select = customSelect.querySelector('.ql-clientSelect');
    select.addEventListener('change', () => {

      if(this.$refs.editor.quill.getSelection()!=null){

          const value = select.options[select.selectedIndex].value;
          this.$refs.editor.quill.insertText(this.$refs.editor.quill.getSelection().index, '['+value+']');
          select.selectedIndex=0;
        }else{
            alert('Posiciona el cursor en donde deseas insertar el valor');
        }
    });



   

    }


}

</script>